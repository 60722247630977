import type { ErrorResponse } from "@remix-run/react";
import { Button } from "@infinitenz/pyrite";
import {
  isRouteErrorResponse,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

import type { loader } from "~/root";

export function ApeironErrorBoundary() {
  const error = useRouteError();
  const data = useRouteLoaderData<typeof loader>("root");
  const sentryStage = data?.config.sentry.stage ?? "prod";

  captureRemixErrorBoundaryError(error);

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    console.error("Route Error:", error);
    return <ErrorPage error={error} />;
  }
  if (error instanceof Error) {
    console.error("Loader, Action Error:", error);
    const renderStackTrace = ["dev", "local"].includes(sentryStage)
      ? error.stack
      : undefined;

    const message = ["dev", "local"].includes(sentryStage)
      ? `ENV ${sentryStage} : ${error.message}`
      : error.message;

    return <ErrorPage errorMessage={message} trace={renderStackTrace} />;
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!
  const errorMessage = "Unknown error";
  return <ErrorPage errorMessage={errorMessage} />;
}

export const ErrorPage = ({
  error,
  errorMessage = "An error occured",
  trace,
}: {
  error?: ErrorResponse;
  errorMessage?: string;
  trace?: string;
}) => {
  const checkErrorMessage =
    typeof error?.data === "string" ? error.data : errorMessage;

  return (
    <>
      <div className="flex min-h-full w-full flex-col items-center justify-center gap-4 bg-gray-100 p-4">
        <h2 className="text-2xl font-semibold">Something went wrong</h2>
        <p>Please try again later</p>
        <code className="text-center text-sm">
          {error?.status} - {error?.statusText}
          <br />
          {checkErrorMessage}
          {trace && <pre className="text-wrap">{trace}</pre>}
        </code>
        <Button className="w-36" onClick={() => window.location.reload()}>
          Reload
        </Button>
        <Button
          className="w-36"
          onClick={() => (window.location.href = "/")}
          variant="outline"
        >
          Home Page
        </Button>
      </div>
    </>
  );
};
